import { Form, Button, notification } from "antd";
import cn from "classnames";
import ArrowRightIcon from "./ArrowRightIcon";
import axios from "axios";

export interface IContactFormProps {
  className?: string;
}

type ContactFormValues = {
  name: string;
  company: string;
  email: string;
  phone: string;
  message: string;
};

const ContactForm = (props: IContactFormProps) => {
  const { className } = props;

  const [form] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();

  const onFinish = async (values: ContactFormValues) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/contact`,
        values
      );

      if (response.status === 200) {
        api.success({
          message: "Message Sent",
          description: "Thank you for contacting us!",
          duration: 2,
          placement: "top",
        });
      } else {
        api.error({
          message: "Message Failed",
          description: "Please try again later",
          duration: 2,
          placement: "top",
        });
      }
    } catch (error) {
      api.error({
        message: "Message Failed",
        description: "Please try again later",
        duration: 2,
        placement: "top",
      });
    }
  };

  return (
    <>
      {contextHolder}
      <Form
        name="contact-form"
        className={cn(className)}
        onFinish={onFinish}
        form={form}
      >
        <div className="p-10">
          <div className="flex flex-row gap-3 w-full">
            <div className="flex flex-col w-1/2">
              <p>Your Name</p>
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please input your name!",
                  },
                ]}
                className="!mb-0"
              >
                <input className="border-0 outline-0 w-full" />
              </Form.Item>
              <hr className="bg-black mt-2"></hr>
            </div>
            <div className="flex flex-col w-1/2">
              <p>Your Company</p>
              <Form.Item
                name="company"
                rules={[
                  {
                    required: true,
                    message: "Please input your company!",
                  },
                ]}
                className="!mb-0"
              >
                <input className="border-0 outline-0 w-full" />
              </Form.Item>
              <hr className="bg-black mt-2"></hr>
            </div>
          </div>
          <div className="flex flex-row gap-3 w-full mt-10">
            <div className="flex flex-col w-1/2">
              <p>Email Address</p>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please input your email!",
                    type: "email",
                  },
                ]}
                className="!mb-0"
              >
                <input className="border-0 outline-0 w-full" />
              </Form.Item>
              <hr className="bg-black mt-2"></hr>
            </div>
            <div className="flex flex-col w-1/2">
              <p>Phone Number</p>
              <Form.Item
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Please input your phone number!",
                    type: "string",
                  },
                ]}
                className="!mb-0"
              >
                <input className="border-0 outline-0 w-full" />
              </Form.Item>
              <hr className="bg-black mt-2"></hr>
            </div>
          </div>
          <div className="mt-10">
            <p>Message</p>
            <Form.Item name="message">
              <textarea className="border-0 outline-0 w-full" />
            </Form.Item>
            <hr className="bg-black mt-2"></hr>
          </div>
        </div>
        <Form.Item className="w-min mx-auto mt-6 mb-6 lg:mb-0">
          <Button
            type="primary"
            size="large"
            shape="round"
            htmlType="submit"
            icon={<ArrowRightIcon />}
            iconPosition="end"
            className="bg-[#1159E4]"
          >
            Send Message
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default ContactForm;
