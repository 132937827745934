import cn from "classnames";

export interface IHamburgerIconProps {
  className?: string;
}

export default function HamburgerIcon(props: IHamburgerIconProps) {
  const { className } = props;

  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#ffffff"
      className={cn(className)}
    >
      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke="#CCCCCC"
        stroke-width="1.152"
      ></g>
      <g id="SVGRepo_iconCarrier">
        {" "}
        <path
          d="M5 8H13.75M5 12H19M10.25 16L19 16"
          stroke="#ffffff"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>{" "}
      </g>
    </svg>
  );
}
