import "./LandingPage.css";
import Solution from "../components/Solution";
import DigitalIDIcon from "../components/DigitalIDIcon";
import MobilePaymentIcon from "../components/MobilePaymentIcon";
import MessagingIcon from "../components/MessagingIcon";
import VPNSolutionIcon from "../components/VPNSolutionIcon";
import Menu from "../components/Menu";
import { Button } from "antd";
import ArrowDownRightIcon from "../components/ArrowDownRightIcon";
import DPFLogo from "../components/DPFLogo";
import AddressIcon from "../components/AddressIcon";
import EmailIcon from "../components/EmailIcon";
import ContactForm from "../components/ContactForm";
import HamburgerIcon from "../components/HamburgerIcon";
import { useState } from "react";
import CloseIcon from "../components/CloseIcon";

const LandingPage = () => {
  const [openMenu, setOpenMenu] = useState(false);

  return (
    <div
      style={{
        fontFamily: "Avenir Next",
      }}
    >
      <div className="bg-[#010106] text-[#FFFFFF] rounded-b-3xl">
        <div
          id="home"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL + "/image-1.jpeg"})`,
          }}
          className="bg-auto lg:bg-contain bg-no-repeat background-container rounded-b-3xl px-5 lg:px-20"
        >
          <nav
            className={`flex flex-row justify-between lg:justify-center items-center pt-5 ${
              openMenu ? "" : ""
            }`}
          >
            <div className="lg:w-1/3">
              <DPFLogo className="cursor-pointer" />
            </div>
            <Menu className="hidden lg:flex lg:w-1/3 h-9 lg:h-[71px]" />
            <div
              className="lg:hidden"
              onClick={() => {
                setOpenMenu(!openMenu);
                window.document.body.style.overflow = openMenu
                  ? "auto"
                  : "hidden";
              }}
            >
              {openMenu ? (
                <CloseIcon className="w-10 h-10 z-20" />
              ) : (
                <HamburgerIcon className="w-14 h-14" />
              )}
            </div>
            <div className="hidden lg:block lg:w-1/3"></div>
          </nav>
          <Menu
            className={`lg:hidden flex-col ${
              openMenu ? "" : "hidden"
            } rounded !fixed left-0 right-0 bottom-0 w-full h-full z-10 text-white`}
            onClick={() => {
              setOpenMenu(!openMenu);
              window.document.body.style.overflow = openMenu
                ? "auto"
                : "hidden";
            }}
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.9)",
            }}
          />
          <div className="lg:px-28 mb-16 lg:mb-36 mt-10 lg:mt-40">
            <p className="font-semibold text-2xl lg:text-3xl lg:leading-9 text-center mb-8 lg:mb-8 text-[#70EEFF]">
              Dhanna Protocol Foundation
            </p>
            <p className="font-semibold text-6xl lg:text-8xl leading-normal lg:leading-[119px] text-center">
              Empowering Innovation Advancing Humanity
            </p>
          </div>
          <div className="relative -bottom-5 lg:-bottom-10 flex flex-col lg:flex-row gap-10 lg:gap-0">
            <video
              className="w-full lg:w-1/2 border-4 border-[#70EEFF]"
              autoPlay
              muted
              loop
            >
              <source
                src={`${process.env.PUBLIC_URL}/dpf_1.mp4`}
                type="video/mp4"
              />
            </video>
            <div className="w-full lg:w-1/2">
              <div className="lg:ml-20 lg:mr-16 mt-6">
                <p>
                  We are a group of innovators, thinkers, and leaders united by
                  a commitment to using technology for social good. As a
                  nonprofit organization, our work is driven by the belief that
                  every technological advancement can catalyze positive change.
                  We collaborate with global experts and partners to design and
                  implement sustainable tech-based solutions that address the
                  pressing challenges of our time.
                </p>
                <Button
                  type="primary"
                  className="mt-10 bg-[#1159E4]"
                  icon={<ArrowDownRightIcon />}
                  iconPosition="end"
                  size="large"
                  shape="round"
                >
                  <a href="#services">See More</a>
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div
          id="services"
          className="flex flex-col lg:flex-row h-[1200px] lg:h-[747px] px-5 lg:px-20"
        >
          <div className="w-full lg:w-1/2 lg:mr-24">
            <div className="my-32">
              <p className="text-inherit text-5xl lg:text-7xl leading-[69px] font-semibold mb-20">
                Innovative <br /> Technologies
                <hr className="h-[2px] w-[117px] bg-[#70EEFF] mt-10"></hr>
              </p>
              <p className="text-inherit">
                Our innovative technologies encompass a range of solutions
                tailored for Privacy, Security and Efficiency. We offer an
                innovative VPN solution designed to surpass current market
                offerings by integrating advanced capabilities while ensuring we
                bridge the weaknesses of the existing VPN providers. We offer
                cutting-edge facial recognition for digital ID verification,
                streamline mobile payment for banks and insurers, and ensure
                robust communication with our chat system capable of functioning
                both offline and online.
              </p>
            </div>
          </div>
          <div
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + "/image-2.png"
              })`,
            }}
            className="w-full h-full lg:w-1/2 bg-contain bg-no-repeat"
          ></div>
        </div>
        <div className="flex flex-col lg:flex-row gap-3 pb-10 lg:pb-20 px-5 lg:px-20 justify-between">
          <Solution
            icon={<DigitalIDIcon />}
            title={
              <p className="font-semibold text-[28px] leading-9">Digital ID</p>
            }
            description={
              <p className="font-normal text-base leading-9">
                Facial Recognition on Edge
              </p>
            }
          />
          <Solution
            icon={<MobilePaymentIcon />}
            title={
              <p className="font-semibold text-[28px] leading-9">
                Mobile Payments
              </p>
            }
            description={
              <p className="font-normal text-base leading-9">
                Banks, MFS, Insurers
              </p>
            }
          />
          <Solution
            icon={<MessagingIcon />}
            title={
              <p className="font-semibold text-[28px] leading-9">Messaging</p>
            }
            description={
              <p className="font-normal text-base leading-9">
                Supports both online and offline
              </p>
            }
          />
          <Solution
            icon={<VPNSolutionIcon />}
            title={
              <p className="font-semibold text-[28px] leading-9">
                VPN Solutions
              </p>
            }
            description={
              <p className="font-normal text-base leading-9">
                Robust privacy and security
              </p>
            }
          />
        </div>
      </div>
      <div id="contact">
        <div className="flex flex-col lg:flex-row px-5 lg:px-32 pt-36 pb-28 gap-32 lg:gap-0">
          <div className="w-full lg:w-1/2 lg:pr-10">
            <p className="font-semibold text-6xl lg:text-7xl leading-[59px]">
              Contact Us
            </p>
            <hr className="h-[2px] w-[117px] bg-[#1159E4] my-10" />
            <p className="font-medium text-lg leading-9">
              For more information about our technologies and services or to
              engage with our team, please reach out through the following
              channels:
            </p>
            <div className="my-10">
              <p className="font-semibold text-3xl m-2">
                Dhanna Protocol Foundation
              </p>
              <div className="my-2 ml-2 flex flex-col justify-center gap-3">
                <div className="flex flex-row">
                  <AddressIcon className="mt-1" />
                  <p className="ml-3">
                    Samuel Lewis Avenue & 55th Street, PH SL55 Building, <br />
                    21st Floor Panama, Republic of Panama
                  </p>
                </div>
                <div className="flex flex-row">
                  <EmailIcon className="my-auto" />
                  <p className="ml-3">admin@dhannafoundationprotocol.com</p>
                </div>
              </div>
            </div>
            <p>
              Additionally, you can fill out our contact form for quick
              inquiries or further information. We look forward to hearing from
              you!
            </p>
          </div>
          <div className="w-full lg:w-1/2 lg:pl-10 relative">
            <div className="hidden lg:block lg:w-[109px] h-0 lg:h-[109px] border-[#1159E4] border-t-2 border-l-2 absolute top-[-55px] left-[-15px]"></div>
            <ContactForm className="shadow-2xl w-full h-full rounded-2xl min-h-[471px]" />
            <div className="hidden lg:block lg:w-[109px] h-0 lg:h-[109px] border-[#1159E4] border-b-2 border-r-2 absolute bottom-[-55px] right-[-55px]"></div>
          </div>
        </div>
      </div>
      <div className="bg-[#010106] text-[#FFFFFF] px-5 lg:px-20 pt-20 pb-10">
        <div className="flex flex-row justify-between">
          <DPFLogo />
          <div className="hidden lg:flex flex-row justify-between items-center gap-2 lg:gap-10">
            <p className="font-semibold text-xl">
              <a href="#home">Home</a>
            </p>
            <p className="font-semibold text-xl">
              <a href="#services">Services</a>
            </p>
            <p className="font-semibold text-xl">
              <a href="#contact">Contact Us</a>
            </p>
          </div>
        </div>
        <hr className="h-[1px] bg-white my-10"></hr>
        <div className="flex flex-row justify-between px-5">
          <p className="w-[800px] font-normal text-sm leading-[22.4px]">
            2024. All rights reserved.
          </p>
          <p className="font-normal text-sm leading-[22.4px]">
            Privacy Policy | Terms&conditions
          </p>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
